import { Duration } from "luxon";

import { ISODateString } from "./ISODateString";
import { ICreateEntity, ISharedEntityFields, IShopifyMirroredEntityFields } from "./shared/SharedEntityFields";

// Currently we support only 30-day plans
export const RECURRING_PRICE_PERIOD = Duration.fromObject({ days: 30 });
// Although Shopify app subscription has 30-day interval and bills merchant every 30 days,
// we will report usage every 10 days, because usage reporting can have arbitrary frequency
// and it is not practical to try to synchronize usage reporting with app subscription schedule
export const USAGE_PRICE_PERIOD = Duration.fromObject({ days: 10 });

export enum AccountPlanStatusEnum {
  // Statuses from Shopify https://shopify.dev/api/admin-graphql/2021-10/enums/AppSubscriptionStatus
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  FROZEN = "FROZEN",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  // Special status for Free Trial plan, we need special status to display the "expired trial" badge
  // After store switches to new plan from expired trial, the trial plan will go to CANCELLED status
  EXPIRED_TRIAL = "EXPIRED_TRIAL",
}
export type AccountPlanStatus = `${AccountPlanStatusEnum}` | AccountPlanStatusEnum;

export enum FeatureEnum {
  // Core functionality that is available on any plan. They are listed here as we are going
  // to revoke access to these features if plan is expired (e.g. when trial period ends)
  CORE_SUBSCRIPTIONS = "CORE_SUBSCRIPTIONS",
  CORE_SUBSCRIPTIONSNEW = "CORE_SUBSCRIPTIONSNEW",
  CORE_CONFIGURATION = "CORE_CONFIGURATION",
  // Below are features protected by authorization
  ANALYTICS = "ANALYTICS", // Standard analytics page, shouldn't be set together with LOOKER_ANALYTICS
  LOOKER_ANALYTICS = "LOOKER_ANALYTICS", // Looker analytics page, shouldn't be set together with ANALYTICS
  SHOPIFY_DEBUG = "SHOPIFY_DEBUG",
  RETENTION = "RETENTION",
  INTEGRATIONS = "INTEGRATIONS",
  PREPAID_SELLING_PLAN = "PREPAID_SELLING_PLAN",
  SEQUENTIAL_SELLING_PLAN = "SEQUENTIAL_SELLING_PLAN",
  ADVANCED_SEQUENTIAL_SELLING_PLAN = "ADVANCED_SEQUENTIAL_SELLING_PLAN",
  SETUP = "SETUP",
  LOYALTY = "LOYALTY",
  BUNDLES = "BUNDLES",
  TRANSLATIONS = "TRANSLATIONS",
  PASSWORDLESS = "PASSWORDLESS",
  TRENDING = "TRENDING",
}

export const FEATURE_DESCRIPTIONS: Record<FeatureEnum, string> = {
  [FeatureEnum.CORE_CONFIGURATION]:
    "This should be enabled for every merchant, except those who have yet to choose a plan",
  [FeatureEnum.CORE_SUBSCRIPTIONS]:
    'This controls access to the "Subscriptions" and "Subscription Programs" nav items in the app. This should be enabled for every merchant, but can be disabled if a merchant has not agreed to billing',
  [FeatureEnum.CORE_SUBSCRIPTIONSNEW]:
    'This controls access to the "Subscriptions" and "Subscription Programs" nav items in the app. This should be enabled for every merchant, but can be disabled if a merchant has not agreed to billing',
  [FeatureEnum.ANALYTICS]: 'This controls access to the "Reports" (formerly "Analytics") nav item in the app',
  [FeatureEnum.LOOKER_ANALYTICS]: 'This controls access to the "Advanced Analytics" nav item in the app',
  [FeatureEnum.SHOPIFY_DEBUG]:
    "This controls access to the super user admin panel and allows merchants to view it. This should be disabled for all merchants.",
  [FeatureEnum.RETENTION]: "Retention",
  [FeatureEnum.INTEGRATIONS]: 'This controls access to the "Integrations" nav item in the app',
  [FeatureEnum.PREPAID_SELLING_PLAN]:
    'This controls access to the prepaid options in the "Subscription Programs" page in the app',
  [FeatureEnum.SEQUENTIAL_SELLING_PLAN]:
    'This controls access to the basic sequential options in the "Subscription Programs" page in the app',
  [FeatureEnum.ADVANCED_SEQUENTIAL_SELLING_PLAN]:
    'This controls access to the advanced sequential options in the "Subscription Programs" page in the app',
  [FeatureEnum.SETUP]: 'This controls access to the "Setup" nav item in the app',
  [FeatureEnum.LOYALTY]: 'This controls access to the "Loyalty" nav item in the app',
  [FeatureEnum.BUNDLES]: 'This controls access to the "Bundles" nav item in the app',
  [FeatureEnum.TRANSLATIONS]: 'This controls access to the "Translations" nav item in the app',
  [FeatureEnum.PASSWORDLESS]: 'This controls access to the "Passwordless" section in Theming > Portal Settings',
  [FeatureEnum.TRENDING]: 'This controls access to the "Trending" section in Theming > Portal Settings',
};
/**
 * For each feature stores flag indicating whether the feature is accessible or not. It's Partial, because
 * JSON might not include some features. If feature is not included, then it is assumed to be not accessible.
 */
export type FeatureAccessMap = Partial<Record<FeatureEnum, boolean>>;

/**
 * Configuration of account plan for using smartrr application. When merchant selects a plan, we produce
 * IAccountPlan from corresponding configuration.
 *
 * @property planName - name of the plan.
 * @property trialDays - number of trial days granted to merchant on this plan. If 0, then free trial is not
 * available to merchant on this plan. This is dynamic property and depends on whether merchant used
 * free trial before.
 * @property planFee - fixed amount of money that merchant pays each period for being on the plan.
 * @property transactionFee - amount of money that smartrr takes per each subscription order.
 * @property percentageFee - fraction of orders' value that smartrr takes per each subscription order (e.g.
 * 0.01 means that smartrr takes 1% of order's value).
 * @property isLocked - if true, means that plan can not be selected by merchant.
 * Merchant can only inquire for a demo.
 * @property features - features access on the current plan.
 */
export interface IAccountPlanConfig {
  planName: string;
  planFee: number;
  transactionFee: number;
  percentageFee: number;
  trialDays: number;
  terms: string;
  cappedAmount: number;
  isLocked: boolean;
  features: FeatureAccessMap;
}

export interface IAccountPlanFeeStructureConfig {
  planFee?: number;
  transactionFee?: number;
  percentageFee?: number;
}
/**
 * Organization's plan for using smartrr application. In Shopify it is called App Subscription
 * (https://shopify.dev/api/admin-graphql/2021-10/objects/AppSubscription).
 *
 * @property planName - name of the plan (matches name of config).
 * @property status - status of App Subscription in Shopify.
 * @property approvedAt - date and time when merchant approved the plan.
 * @property reportUsageAt - date and time when usage should be reported per this plan. If plan has not a
 * usage-based pricing component, then is undefined.
 * @property trialPeriodStart - date and time when trial period starts per this plan. If plan did not grant
 * a trial, then is undefined.
 * @property trialPeriodEnd - date and time when trial period ends per this plan. If plan did not grant
 * a trial, then is undefined.
 * @property trialDays - number of trial days granted to merchant on this plan. They are counted from the
 * approval date.
 * @property usageLineItemShopifyId - Shopify ID of app subscription line item where we report usage-based price
 * component of the plan.
 * @property confirmationUrl - URL pointing to Shopify page where app subscription can be approved by merchant.
 */
export interface IAccountPlan extends IShopifyMirroredEntityFields {
  planName: string;
  status: AccountPlanStatus;
  approvedAt?: ISODateString;
  trialPeriodStart?: ISODateString;
  trialPeriodEnd?: ISODateString;
  reportUsageAt?: ISODateString;
  trialDays: number;
  usageLineItemShopifyId?: string;
  confirmationUrl?: string;
  planFee: number;
  transactionFee: number;
  percentageFee: number;
  features: FeatureAccessMap;
}

export type IAccountPlanCreate = ICreateEntity<IAccountPlan>;

/**
 * Record that tracks usage-based component of plan price (transaction fee + percentage fee). We calculate
 * usage over certain period of time and post it to Shopify as AppUsageRecord
 * (https://shopify.dev/api/admin-graphql/2021-10/objects/AppUsageRecord). Shopify will charge merchant
 * for two price components:
 * 1. Fixed price (fixedFee) - Shopify can do this charge by itself.
 * 2. Usage price (transaction fee + percentage fee) - Shopify charges it based on AppUsageRecord we've created.
 *
 * @property usageStartAt - date and time when usage period started.
 * @property usageEndAt - date and time when usage period ended.
 * @property shopifyId - shopify ID of AppUsageRecord that corresponds to our db record. shopifyId can be null if
 * calculated price is 0. In this case we can't create usage record in Shopify, but we still create record in our
 * database so that we know that usage for the period was already calculated.
 * @property price - usage-based price of the plan (transaction fee + percentage fee).
 */
export interface IAccountPlanUsageRecord extends ISharedEntityFields {
  usageStartAt: ISODateString;
  usageEndAt: ISODateString;
  shopifyId?: string;
  price: number;
  transactionFee?: number;
  percentageFee?: number;
  sumOrdersGmv?: number;
  orderCount?: number;
}

export type IAccountPlanUsageRecordCreate = ICreateEntity<IAccountPlanUsageRecord>;
