import { jwtPropertyName } from "@smartrr/shared/constants";
import { FeatureEnum, IAccountPlan } from "@smartrr/shared/entities/AccountPlan";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";
import { getFromLocalStorage } from "@smartrr/shared/utils/localStorageAccess";

import { GeneralSmartrrDispatch } from "@vendor-app/app/_state/typedVendorRedux";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

// const MUTUALLY_EXCLUSIVE_FEATURES = [FeatureEnum.ANALYTICS, FeatureEnum.LOOKER_ANALYTICS]

export function isFeatureAvailable(
  feature: FeatureEnum,
  user: IVendorUser | null,
  activePlan: IAccountPlan | null
): boolean {
  return checkFeatureAvailability(feature, user, activePlan);
}

export function useIsFeatureAvailable(feature: FeatureEnum): boolean {
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);
  return checkFeatureAvailability(feature, user, activePlan);
}

const checkFeatureAvailability = (
  feature: FeatureEnum,
  user: IVendorUser | null,
  activePlan: IAccountPlan | null
) => {
  if (user?.isSuperUser === true) {
    // Superuser can access any feature even if it is not available to the store
    return true;
  }

  // Only looker analytics needs to be true if both are enabled
  if (feature === FeatureEnum.ANALYTICS) {
    // Check if Looker analytics is enabled in the active plan
    if (activePlan?.status === "ACTIVE" && activePlan.features.LOOKER_ANALYTICS) {
      return false;
    }
  }

  // Setup only available to users with active plans
  if (feature === FeatureEnum.SETUP) {
    if (activePlan?.status === "ACTIVE") {
      return true;
    }
    return false;
  }

  if (feature === FeatureEnum.LOYALTY) {
    if (activePlan?.status === "ACTIVE" && activePlan.features.LOYALTY) {
      return true;
    }
  }

  if (!activePlan) {
    return false;
  }

  return activePlan.features[feature] === true;
};

export function isSuperUserSwitchDurationValid(localStorageKey: string): boolean {
  const localStorageEntry = getFromLocalStorage(localStorageKey);
  if (localStorageEntry) {
    const { duration } = JSON.parse(localStorageEntry);
    const currentTime = Date.now();
    if (duration > currentTime) {
      return true;
    }
  }
  return false;
}

export const updateSuperUserStatusRedux = (
  dispatch: GeneralSmartrrDispatch,
  isSuperUser: boolean,
  user: IVendorUser,
  auth_token: string,
  activeOrg: IOrganization
) =>
  dispatch({
    type: "LOADED_USER",
    payload: {
      [jwtPropertyName]: auth_token,
      user: { ...user!, isSuperUser },
      activeOrganizationId: activeOrg?.id,
    },
  });
